<template>
  <el-main class="scrollbar" v-show="userInfo">
    <el-row type="flex" class="flexFJA el-row" justify="center">
      <el-col>
        <el-avatar :src="userInfo.avatar"></el-avatar>
      </el-col>
      <el-col>
        <el-link :underline="false">{{ userInfo.realName }}</el-link>
      </el-col>
      <el-col>
        <el-link :underline="false">{{ userInfo.roleNames }}</el-link>
      </el-col>
      <el-col>
        <el-link :underline="false">{{ userInfo.industries }}</el-link>
      </el-col>
      <el-col>
        <el-link :underline="false">{{ userInfo.introduce }}</el-link>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { formatDate } from "@/common/date";

export default {
  name: "aboutMe",
  data() {
    return { 
    };
  },
  props:{
    userInfo:Object
  },
  components: {},
  computed: {},
  watch: {
    userInfo: {
      handler: (val, olVal) => {
        console.log("我变化了", val, olVal); //但是val和olVal值一样
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    // this.userInfo = this.$store.state.userInfo;
    // this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
  },
  activated() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-main {
  height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  .el-row {
    text-align: center;

    .el-avatar {
      width: 5rem;
      height: 5rem;
      background: transparent !important;
    }
    .el-link {
      font-size: 15px;
      margin-top: 10px;
    }
  }
}
</style>
