<template>
  <el-main>
    <el-form
      :model="formData"
      :rules="rules"
      ref="bankCardInfo"
      label-width="100px"
      class="demo-formData"
    >
      <el-form-item label="收款人姓名" prop="bankUserName">
        <el-input
          type="text"
          v-model="formData.bankUserName"
          clearable
          placeholder="请输入开户人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="银行账号" prop="bankCardNo">
        <el-input
          type="text"
          v-model="formData.bankCardNo"
          clearable
          placeholder="请输入银行账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="开户行" prop="bankName">
        <el-input
          placeholder="详细到支行"
          type="text"
          v-model="formData.bankName"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')"
          >提交</el-button
        >
        <el-button @click="resetForm('formData')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
import { formatDate } from "@/common/date";
import { systemUserFillBankCard } from "@/api/costManagement";

export default {
  name: "bankCardInfo",
  data() {
    var validateBankCardNum = (rule, value, callback) => {
      let reg = /^([1-9]{1})(\d{15}|\d{18})$/; //银行卡号
      if (value == null) {
        callback(new Error("请输入密码"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确的银行卡号！"));
        } else {
          callback();
        }
      }
    };
    return {
      that: this,
      formData: {
        bankUserName: null,
        bankName: null,
        bankCardNo: null,
      },
      rules: {
        bankUserName: [
          { required: true, message: "请输入收款人姓名", trigger: "blur" },
        ],
        bankCardNo: [
          { required: true, message: "请输入正确的银行卡号", trigger: "blur" },
          { validator: validateBankCardNum, trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入开户行", trigger: "blur" },
        ],
      },

      verifyCodeInfo: null,
    };
  },
  props: {
    userInfo: Object,
  },
  components: {},
  computed: {},
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        if (!newVal) {
          let uInfo = window.localStorage.getItem("userInfo");
          console.log(uInfo);
          let userInfo = JSON.parse(JSON.stringify(uInfo));
          this.formData.bankName = userInfo.bankName;
          this.formData.bankUserName = userInfo.bankUserName;
          this.formData.bankCardNo = userInfo.bankCardNo;
          this.$forceUpdate();
        } else {
          let userInfo = JSON.parse(JSON.stringify(newVal));
          let { bankName, bankUserName, bankCardNo } = userInfo;
          this.formData = { bankName, bankUserName, bankCardNo };
        }
      },
      deep: true,
      immediate:true
    },
  },
  created() {},
  mounted() {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
  },
  activated() { 
  },
  methods: {
    submitForm() {
      this.$refs["bankCardInfo"].validate((valid) => {
        if (valid) {
          let formData = { ...this.formData };
          systemUserFillBankCard(formData)
            .then((response) => {
              if (response.success) {
                this.$emit("listenBankCardInfo", {
                  type: "sure",
                  isClose: true,
                });
                this.$message({
                  type: "success",
                  message: "添加银行卡信息成功!",
                });
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            })
            .catch(() => {});
        } else {
          console.log("error submit!!");
        }
      });
    },
    resetForm() {
      this.$refs["bankCardInfo"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  // padding: 8px !important;
  //   height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  .demo-formData {
    margin-top: 2rem;
    .verifyCode {
    }
  }
}
</style>
