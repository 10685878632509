<template>
  <el-main class="scrollbar">
    <el-row type="flex" class="flexFJA el-row" justify="center">
      <el-col v-show="!dingdingAuthorizeSuccess">
        <!-- id必写，style是为了调整二维码大小，二维码官方固定尺寸，只好出此下策。 -->
        <div id="login_container" style="transform: scale(0.8)"></div>
      </el-col>
      <el-col v-if="dingdingAuthorizeSuccess">
        <div class="flexJA ding">
          <span>已成功绑定</span>
          <img
            src="https://oss.eggxiaoer.com/web/hermes/%E9%92%89%E9%92%8901.png"
            alt=""
          />
        </div>
        <div class="flexJA nick" v-show="userInfo">
          <span>钉钉名：</span>
          <el-link :underline="false" v-show="userInfo.dingTalkDisplayName">{{
            userInfo.dingTalkDisplayName
          }}</el-link>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { formatDate } from "@/common/date";
import { bindDingTalk, getUserInfo } from "@/api/user";

export default {
  name: "dingdingAuthorize",
  data() {
    return {
      dingdingAuthorizeSuccess: false,
      // dingTalkInfo: null,
    };
  },
  props: {
    userInfo: Object,
  },
  components: {},
  computed: {},
  watch: {
    userInfo: {
      handler(newValue, oldValue) {
        // var that = this;
        console.log(newValue);
        this.checkBindStatus();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
  },
  activated() {},
  methods: {
    checkBindStatus() {
      if (this.userInfo && this.userInfo.dingTalkUnionId) {
        console.log(this.userInfo);
        this.dingdingAuthorizeSuccess = true;
      } else {
        console.log("nothis.userInfo");

        const { code, type } = this.$route.query;
        if (code) {
          //登录接口
          this.handleCodeLogin(code);
        } else if (type == "bindDingTalk") {
          //钉钉二维码
          this.ddLoginInit();
        } else {
          //钉钉二维码
          this.ddLoginInit();
        }
      }
    },
    ddLoginInit() {
      let locationOrigin = window.location.origin;
      //钉钉扫码流程：扫码成功登录后会自动跳到这个url页面，url路径会携带code，你拿到这个code，调用登录接口成功就跳转。
      //你的项目页面
      let url = encodeURIComponent(locationOrigin + "/personalCenter");
      // console.log(url);
      // appid
      let appid = "ding5s3bshf1isa8sz0z";
      // 钉钉自己的url 修改上面俩，不需要动这个
      let goto = encodeURIComponent(
        `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`
      );
      let obj = DDLogin({
        id: "login_container", //这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
        goto: goto, //请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
        width: "100%", //官方参数 365
        height: "300", //官方参数 400
      });
      let handleMessage = function (event) {
        let origin = event.origin;
        // console.log("origin", event.origin);
        if (origin == "https://login.dingtalk.com") {
          //判断是否来自ddLogin扫码事件。
          let loginTmpCode = event.data;
          //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
          console.log("loginTmpCode", loginTmpCode);
          //此步拿到临时loginTmpCode换取正式code
          window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}&loginTmpCode=${loginTmpCode}`;
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    },
    handleCodeLogin(code) {
      console.log(code);
      let data = {
        tmpAuthCode: code,
      };
      bindDingTalk(data)
        .then((response) => {
          if (response.success) {
            this.$emit("listenBindDingTalk", {
              type: "sure",
              isClose: true,
              info: response.data,
            });
            this.getUserInfo();
            this.$message({ type: "success", message: "钉钉绑定成功!" });
            this.dingdingAuthorizeSuccess = true;
          } else {
            this.dingdingAuthorizeSuccess = false;
            //钉钉二维码
            this.ddLoginInit();
          }
          this.$router.push({ query: {} });
        })
        .catch(() => {
          this.dingdingAuthorizeSuccess = false;
          //钉钉二维码
          this.ddLoginInit();
          this.$router.push({ query: {} });
        });
    },
    getUserInfo() {
      getUserInfo().then((response) => {
        if (response.success) {
          window.localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data)
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  // height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  .el-row {
    text-align: center;
    .ding {
      img {
        width: 20px;
      }
    }
    .nick {
      margin-top: 10px;
      .el-link {
        font-size: 15px;
      }
    }
  }
}
</style>
