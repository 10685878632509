<template>
  <el-main>
    <el-radio v-model="radio" label="1">原密码修改</el-radio>
    <el-radio v-model="radio" label="2">验证码修改</el-radio>
    <el-form
      :model="formData"
      :rules="rules"
      ref="changePassword"
      label-width="100px"
      class="demo-formData"
    >
      <el-form-item label="原密码" prop="oldPassword" v-if="radio == 1">
        <el-input
          type="password"
          v-model="formData.oldPassword"
          show-password
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item
        label="获取验证码"
        prop=""
        v-if="radio == '2'"
        class="verifyCode"
      >
        <div class="flexFJA" v-if="userInfo.phoneNumberAfter">
          <i class="el-icon-mobile-phone"></i>
          <div class="">当前手机号{{ userInfo.phoneNumberAfter }}</div>
          <el-button type="primary" size="small" @click="getVerifyCode"
            >获取验证码</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="验证码" prop="verifyCode" v-if="radio == '2'">
        <el-input
          type="text"
          clearable
          v-model="formData.verifyCode"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="newPassword">
        <el-input
          type="password"
          v-model="formData.newPassword"
          show-password
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="formData.checkPass"
          show-password
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')"
          >提交</el-button
        >
        <el-button @click="resetForm('formData')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
import { formatDate } from "@/common/date";
import { changePassword, getResetPasswordVerifyCode } from "@/api/login";


export default {
  name: "other",
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      //var passwordreg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/; //数字、字母、特殊字符
      var passwordreg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,20}$/; //大小写字母，数字，特殊字符
      if (value == null) {
        callback(new Error("请输入密码"));
      } else {
        if (!passwordreg.test(value)) {
          callback(
            new Error(
              "密码必须包含大写字母、小写字母、数字和特殊字符的其中三种，且长度为8到12位！"
            )
          );
        } else {
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formData.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      formData: {},
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          // { validator: validatePass, trigger: "blur" },
        ],
        newPassword: [
          // { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        checkPass: [
          // { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        verifyCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      radio: "1",
      type: null, // 1 重置密码， 2 修改密码
       
      verifyCodeInfo: null,
    };
  },
  props:{
    userInfo:Object
  },
  components: {},
  computed: {},
  watch: {
    userInfo: {
      handler: (val, olVal) => {
        console.log("我变化了", val, olVal); //但是val和olVal值一样
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
  },
  activated() {},
  methods: { 
    getVerifyCode() {
      getResetPasswordVerifyCode(this.userInfo.phoneNumber)
        .then((response) => {
          if (response.success) {
            this.formData.verifyCodeId = response.data;
            this.$message({ type: "success", message: "验证码发送成功!" });
          } else {
          }
        })
        .catch(() => {});
    },
    submitForm() {
      this.$refs["changePassword"].validate((valid) => {
        if (valid) {
          console.log(valid);
          if (this.radio == 1) {
            this.formData.uid = this.userInfo.uid;
          } else {
            this.formData.phoneNumber = this.userInfo.phoneNumber;
          }
          let formData = { ...this.formData };
          changePassword(formData)
            .then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "修改成功!" });
                window.localStorage.clear();
                window.location.href = window.location.origin + "/login";
              } else {
              }
            })
            .catch(() => {});
        } else {
          console.log("error submit!!");
        }
      });
    },
    resetForm() {
      this.$refs["changePassword"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  // padding: 8px !important;
  //   height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  .demo-formData {
    margin-top: 2rem;
    .el-form-item{
      margin-bottom: 36px;
    }
    .verifyCode {
    }
  }
}
</style>
