<template>
  <el-main class="scrollbar">
    <el-row type="flex" class="flexFJA el-row" justify="center">
      <el-col>
        <el-avatar :src="userInfo.avatar"></el-avatar>
      </el-col>
      <el-col>
        <el-link :underline="false">{{ userInfo.realName }}</el-link>
      </el-col>
       <el-col>
        <el-link :underline="false">{{ userInfo.roleNames }}</el-link>
      </el-col>
      <el-col>
        <el-link :underline="false">{{ userInfo.industries }}</el-link>
      </el-col>
       <el-col>
        <el-link :underline="false">{{ userInfo.introduce }}</el-link>
      </el-col>
    </el-row>
     
  </el-main>
</template>

<script>
import { formatDate } from "@/common/date";

export default {
  name: "other",
  data() {
    return {
      userInfo: null,
    };
  },
  components: {},
  computed: {},
  watch: {},
  created() {
    this.userInfo = this.$store.state.userInfo;
  },
  mounted() {
    // this.getLatestList();
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "MM-dd hh:mm");
    },
  },
  activated() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-main {
  // padding: 8px !important;
  height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  .el-row {
    text-align: center;

    .el-avatar {
      width: 5rem;
      height: 5rem;
      background: transparent !important;
    }
    .el-link{
        font-size: 15px;
        margin-top: 10px;
    }
  }
}
</style>
