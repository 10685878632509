<template>
  <el-row type="flex" justify="space-around" class="home">
    <el-col class="left">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="headerTitle">关于我</span>
        </div>
        <aboutMe :userInfo="userInfo"></aboutMe>
      </el-card>
    </el-col>
    <el-col class="right">
      <!-- 我的待办 -->
      <el-card class="box-card needDealt">
        <div class="clearfix">
          <!-- <span>我的待办</span> -->
          <el-tabs
            style="width: 100%"
            v-model="activeName"
            @tab-click="handleClick"
            :stretch="false"
          >
            <el-tab-pane label="修改密码" name="first">
              <changePassword :userInfo="userInfo"></changePassword>
            </el-tab-pane>
            <el-tab-pane label="绑定钉钉" name="second">
              <dingdingAuthorize
                :userInfo="userInfo"
                v-on:listenBindDingTalk="listenBindDingTalk"
              ></dingdingAuthorize>
            </el-tab-pane>
            <el-tab-pane label="银行卡信息" name="third">
              <bankCardInfo
                :userInfo="userInfo"
                v-on:listenBankCardInfo="listenBankCardInfo"
              ></bankCardInfo>
            </el-tab-pane>
          </el-tabs>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
      </el-card>
      <!-- 项目概览 -->
    </el-col>
  </el-row>
</template>

<script>
import aboutMe from "@/components/personalCenter/aboutMe";
import other from "@/components/personalCenter/other";
import changePassword from "@/components/personalCenter/changePassword";
import dingdingAuthorize from "@/components/personalCenter/dingdingAuthorize";
import bankCardInfo from "@/components/personalCenter/bankCardInfo";
import { getUserInfo, getUserInfoById} from "@/api/user";

export default {
  name: "personalCenter",
  data() {
    return {
      activeName: "first",
      todoList: [], //待办事项
      scheduleId: null,
      timeSelect: true,
      projectOverviewTime: "-7",
      projectStatisticsSelect: true,
      projectStatisticsTime: "-7",
      indexType: null,
      userInfo: null,
    };
  },
  components: {
    aboutMe,
    other,
    changePassword,
    dingdingAuthorize,
    bankCardInfo,
  },

  computed: {},
  watch: {},
  created() {},
  mounted() {
    // this.getUserInfo();
    this.getUserInfoById()
    const { code, type } = this.$route.query;
    if (code || type) {
      this.activeName = "second";
    } else {
    }
  },
  activated() {
    this.activeName = "first";
  },
  methods: {
    listenBankCardInfo(params) {
      console.log(params);
      let { close, type, info } = params;
      if (type == "sure") {
        // this.getUserInfo();
        this.getUserInfoById()
      }
    },
    listenBindDingTalk(params) {
      console.log(params);
      let { close, type, info } = params;
      if (type == "sure") {
        if (info.dingTalkUnionId) {
          this.userInfo.dingTalkDisplayName = info.dingTalkDisplayName;
          this.userInfo.dingTalkUnionId = info.dingTalkUnionId;
          this.getUserInfoById();
        }
      }
    },
    getUserInfo() {
      getUserInfo().then((response) => {
        if (response.success) {
          window.localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data)
          );
          this.userInfo = response.data;
          this.userInfo.phoneNumberAfter =
            this.userInfo.phoneNumber.substr(0, 3) +
            "****" +
            this.userInfo.phoneNumber.substr(7);
        }
      });
    },
    getUserInfoById(){
      let uid = window.localStorage.getItem('userId')
      getUserInfoById(uid).then((response) => {
        if (response.success) {
          window.localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data)
          );
          this.userInfo = response.data;
          this.userInfo.phoneNumberAfter =
            this.userInfo.phoneNumber.substr(0, 3) +
            "****" +
            this.userInfo.phoneNumber.substr(7);
        }
      });
    },
    handleClick(tab, event) {
      // console.log(tab.index, event);
      let index = tab.index;
      this.indexType = tab.index;
      if (index == 0) {
      } else if (index == 1) {
      } else if (index == 2) {
        this.getUserInfoById()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .left {
    width: 36%;
    text-align: left;
    .needDealt {
      .cardHeader {
        display: flex;
        justify-content: flex-start;
        // align-items: baseline;
      }
    }
    .projectOverview {
      margin-top: 30px;
    }
    .headerTitleLink:hover {
      color: #409eff;
      cursor: pointer;
    }
  }
  .right {
    width: 55%;
    text-align: left;
    .el-card {
      margin-bottom: 10px;
    }
  }
  .headerTitle {
    font-weight: bold;
  }
  .timeSelect {
    color: #409eff;
  }
}
.clearfix {
  .time {
    margin-left: 20px;
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.is-selected {
  color: #f8a535;
  font-size: 16px;
  margin-top: 5px;
}
.tip {
  color: red;
  font-size: 18px;
  text-align: left;
  margin-left: 15px;
}
.thingsTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
}
</style>
