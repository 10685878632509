/*
 * @Description:
 * @Author: transparent
 * @Date: 2022-02-17 09:56:40
 * @LastEditTime: 2022-03-04 14:40:05
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/costManagement.js
 */

import request from "@/utils/request";

//分页查询费用列表
export function getExpenseList(param) {
  return request({
    url: "/admin/expense/paging-list",
    method: "post",
    data: param
  });
}

//分页查询费用列表（财务）
export function getFinancialExpenseList(param) {
  return request({
    url: "/admin/expense/financial-paging-list",
    method: "post",
    data: param
  });
}
//新增费用信息
export function addExpense(param) {
  return request({
    url: "/admin/expense/add",
    method: "post",
    data: param
  });
}

//查询费用信息
export function getExpenseInfo(id) {
  return request({
    url: `/admin/expense/get?id=${id}`,
    method: "get"
  });
}
//查询费用明细列表
export function getExpenseDetailList(param) {
  return request({
    url: "/admin/expense/detail/list",
    method: "post",
    data: param
  });
}

//分页查询费用明细列表
export function getExpenseDetailPagingList(param) {
  return request({
    url: "/admin/expense/detail/paging-list",
    method: "post",
    data: param
  });
}
//新增费用明细
export function addExpenseDetail(param) {
  return request({
    url: "/admin/expense/detail/add",
    method: "post",
    data: param
  });
}
//编辑费用明细
export function editExpenseDetail(param) {
  return request({
    url: "/admin/expense/detail/edit",
    method: "post",
    data: param
  });
}
//删除费用明细
export function deleteExpenseDetail(param) {
  return request({
    url: "/admin/expense/detail/delete",
    method: "post",
    data: param
  });
}

//查询日程的行程列表
export function getScheduleTriplist(keyword) {
  return request({
    url: `admin/schedule/trip-list?id=${keyword}`,
    method: "get"
  });
}

//申请人确认
export function expenseConfirm(param) {
  return request({
    url: "/admin/expense/confirm",
    method: "post",
    data: param
  });
}
//申请人批量确认
export function expenseBatchConfirm(param) {
  return request({
    url: "/admin/expense/batch-confirm",
    method: "post",
    data: param
  });
}

//财务预审
export function expensePreApprove(param) {
  return request({
    url: "/admin/expense/pre-approve",
    method: "post",
    data: param
  });
}
//财务批量预审
export function expenseBatchPreApprove(param) {
  return request({
    url: "/admin/expense/batch-pre-approve",
    method: "post",
    data: param
  });
}
//
//催财务审核
export function expenseNoticeFinancial(param) {
  return request({
    url: "/admin/expense/notice-financial",
    method: "post",
    data: param
  });
}

//添加费用明细附件
export function expenseAddAttachments(param) {
  return request({
    url: "/admin/expense/detail/add-attachments",
    method: "post",
    data: param
  });
}
//删除费用明细附件
export function expenseDeleteAttachments(param) {
  return request({
    url: "/admin/expense/detail/delete-attachments",
    method: "post",
    data: param
  });
}


//发起钉钉报销申请
export function expenseStartFormalApprove(param) {
    return request({
      url: "/admin/expense/start-formal-approve",
      method: "post",
      data: param
    });
  }

  //
  
  //报销银行账户信息
export function systemUserFillBankCard(param) {
    return request({
      url: "/admin/system/user/fill-bank-card",
      method: "post",
      data: param
    });
  }
  
//据日程查询同行人员
export function getScheduleRelatedUserList(keyword) {
  return request({
    url: `admin/schedule/related-user-list?id=${keyword}`,
    method: "get"
  });
}

//admin/expense/notice-user
export function expenseNoticeUser(param) {
  return request({
    url: "/admin/expense/notice-user",
    method: "post",
    data: param
  });
}